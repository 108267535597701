import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { HeadingBasic } from "../../components/heading/heading"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { AcfListImage } from "../../components/list-image"
import { AcfColHeadline } from "../../components/columned-headline"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* ==========================================================================
    Render
   ========================================================================== */

const BoD = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "board-of-directors", idType: URI) {
          title
          blocks {
            name
            ...CoreCoverBlock
            ...AcfListImageBlock
            ...AcfColHeadlineBlock
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreCoverBlock
              ...AcfListImageBlock
              ...AcfColHeadlineBlock
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.page?.blocks
  const seo = data.wordPress.page.seo
  const cover =
    wordPress &&
    wordPress?.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const listImages = wordPress?.reduce((result, currentValue) => {
    if (currentValue.name === "acf/list-image") {
      result.push(currentValue)
    }
    return result
  }, [])

  const navTabs = [
    {
      name: "Board of Directors",
      target: "bod",
    },
  ]

  return (
    <Layout location={location} currentLocation={location.uri} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />

      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.page?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Board of directors"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "acf/col-headline":
            return <AcfColHeadline data={item} key={`col-headline-${i}`} />
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                        "The Investor Relations Section is dedicated for shareholders and investors. Here you will find updated annual report from Sinarmas Limited, PT. Bumi Serpong Damai Tbk, and PT. Duta Pertiwi Tbk",
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          // case "acf/list-image":
          //   return <AcfListImage data={item} key={`${item.name}${i}`} />
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}

      {listImages && listImages.length > 0 && (
        <>
          <Container>
            <NavTabs scrollable className={`pt-5`} data={navTabs} />
          </Container>
          <TabPane id="bod" className="active">
            {listImages[0] && <AcfListImage data={listImages[0]} />}
          </TabPane>
        </>
      )}
    </Layout>
  )
}

export default BoD
